import React from 'react'
import MainStructure from '../components/general/MainStructure'
import { useNavigate } from 'react-router-dom'
 
import { useContext , useState, useRef, useEffect} from 'react'
import { useAuth } from '../AuthContext'

import { faRobot, faStar, faRocket, faFaceSmile, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faDumbbell, faCog, faWandMagic, faUserTie, faFlagCheckered, faLightbulb, faFont, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'react-bootstrap';

import { Button, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Bold } from 'react-feather'
import { LinkedInConnectButton } from '../components/general/LinkedInAuth'
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'


function formatDateToUserTimezone(gmtDate) {

  if(gmtDate == undefined) return "";

    // Parse the GMT date string into a Date object
    const dateParts = gmtDate.split(/[- :]/);
    const date = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]));
  
    // Format the date according to the user's locale and timezone
    const formattedDate = date.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // Use 12-hour format
      hour: 'numeric', // Display hours
      minute: 'numeric', // Display minutes
      month: 'long', // Display month
      day: 'numeric', // Display day
      year: 'numeric' // Display year
    });
  
    return formattedDate;
  }


  function zuluToDate(zuluDate) {
    // Create a new Date object from the Zulu date string
    const date = new Date(zuluDate);
  
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + date.getUTCDate()).slice(-2);
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);
  
    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    return formattedDate;
  }

  const formatContentWithBreaks = (content) => {
    return content.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < content.length - 1 && <br />} {/* Add <br/> except for the last line */}
      </React.Fragment>
    ));
  };


function WritePost() 
{

    const tooltipRef  = useRef(null);
    const myContext   = useAuth();
    const infoLoaded  = myContext.infoLoaded;

    const [errorMessage, seterrorMessage] = useState('');
    const [showModal2, setshowModal2] = useState(false);

    const [showFullContent, setShowFullContent] = useState({});
    const [showFullContent2, setShowFullContent2] = useState({});
    const [showFullContent3, setShowFullContent3] = useState({});


    const [activeDraftId,     setactiveDraftId]     = useState(null);
    const [activeScheduledId, setactiveScheduledId] = useState(null);

    const toggleShowFullContent = (index) => {
      setShowFullContent(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };


    const toggleShowFullContent2 = (index) => {
      setShowFullContent2(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };



    const toggleShowFullContent3 = (index) => {
      setShowFullContent3(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };



    const [schedulePostModalShow, setschedulePostModalShow] = useState(false);
    const handleCloseScheduleModal = () => setschedulePostModalShow(false);
    const handleShowScheduleModal = () => setschedulePostModalShow(true);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = date => setSelectedDate(date);



  const handleConfirmSchedulePost = async (date) => {

    const jwtToken = myContext.jwtToken;

    handleCloseScheduleModal();
    setloading(true);
    setdisabled(true);

    var currentScheduledID = 0;
    if(activeScheduledId != null ) currentScheduledID = activeScheduledId;


    const response = await fetch('https://postcraftai.com/wp-json/api/v1/schedulePost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
        
      },
      body: JSON.stringify({ scheduledDate: date, text: text, currentScheduledID: currentScheduledID }),
    }).then(data => data.json()).then(MYJSON =>{
        

      if(activeDraftId != null)
        {
          deleteDraft(activeDraftId);
          setactiveDraftId(null)
        }

        console.log(MYJSON)

        setloading(false);
        setdisabled(false);
        setText("");

        const scheduledItemSingle = {
            content: text,
            scheduled_date: zuluToDate(date)
        }

        if(activeScheduledId != null)
        {

        }
        else
        {
              myContext.addScheduledItem(scheduledItemSingle);
        }
         
       
        setactiveScheduledId(null)

        
    });

    if (response.ok) {
      // Handle success
      console.log('Date scheduled successfully');
    } else {
      // Handle error
      console.error('Failed to schedule date');
    }
    handleCloseScheduleModal();
  };

  //-------------



    const [loading, setloading] = useState(false);
    const [disabled, setdisabled] = useState(false);



  const navigate =useNavigate();

  const [text, setText] = useState('');
  const [showModal, setShowModal] = useState(false);


  const handleClose = () => setshowModal2(false);
  const handleShow = () => setshowModal2(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


    const handleToggleModal = () => {
        setShowModal(!showModal);
      };


    const handleChange = (event) => {
        setText(event.target.value);
      };

 
 


  const writeTextBox = () =>{
    return(<div className=''>
        <textarea className='form-control' maxLength={2999} style={{minHeight: 100}} value={text} placeholder='Start composing your post...'
        onChange={handleChange}></textarea>
    </div>)
  }


  const toolsBox = () =>{
    return(<>
    <div className="row mt-3">
        <div className="col-12">
            <ul className='simpleList'>

            <li><button disabled={disabled}  onClick={()=>{
           
        }} className='btn   btn-white bnRadius'><FontAwesomeIcon icon={faFaceSmile} /></button></li>

            <li><button disabled={disabled}  onClick={()=>{
           
        }} className='btn   btn-white bnRadius'><FontAwesomeIcon icon={faFont} /></button></li>

        <li className='tooltip-container'><button disabled={disabled} onClick={()=>{
            handleToggleModal()
        }} className='btn   btn-white bnRadius'><FontAwesomeIcon icon={faRobot} /></button>
        
        {showToolTip()}
        
        </li>

        

        <li><button disabled={disabled}  className='btn btn-grey-custom bnRadius' onClick={() =>{

            saveDraft()
                
            }}>Save Draft</button></li>

        <li><button disabled={disabled}  className='btn   btn-orange-custom bnRadius' onClick={() =>{
             scheduleit()
            }}>Schedule It</button></li>


<li><button disabled={disabled}  className='btn   btn-primary-custom bnRadius' onClick={() =>{
                postonLinkedIn()
            }}>Post to LinkedIn Now</button></li>

            </ul>
        </div>
    </div>
    </>)
  }


  function scheduleit()
  {
    if(text.length < 25)
    {
            handleShow();
            seterrorMessage("You must start typing something to schedule as a post. Min 25 chars.")
    }
    else
    {
        handleShowScheduleModal();
    }
   
  }

  function saveDraft()
  {
    if(text.length < 25)
    {
            handleShow();
            seterrorMessage("You must start typing something to save as draft. Min 25 chars.")
    }
    else
    {
        setloading(true);
        setdisabled(true);

        var currentDraft = 0;

        if(activeDraftId != null)
          {
            currentDraft = activeDraftId
          }

        const jwtToken = myContext.jwtToken;
        fetch('https://postcraftai.com/wp-json/api/v1/saveDraft', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`
            },
            body: JSON.stringify({ textBoxContent:  text, currentDraft: currentDraft })
        }) .then(data => data.json()).then(MYJSON =>{


            setText("")
            setloading(false);
            setdisabled(false);
            
            console.log(MYJSON)


            const singleItem = {
              pid: MYJSON.post_id,
              content: text,
              scheduled_date: zuluToDate("2024-01-01 10:00:00")
          }

          myContext.addDraftItem(singleItem);
          setactiveDraftId(null)

            
        });

    }



  }

  function postonLinkedIn()
  {


    if(text.length < 50)
    {
            handleShow();
            seterrorMessage("You must start typing something to post. Min 50 cahrs.")
    }
    else
    {

      if(activeDraftId != null)
        {
          deleteDraft(activeDraftId);
          setactiveDraftId(null)
        }


            setloading(true);
            setdisabled(true);

            const jwtToken = myContext.jwtToken;
            fetch('https://postcraftai.com/wp-json/api/v1/postOnLinkedin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ textBoxContent:  text })
            }) .then(data => data.json()).then(MYJSON =>{


                setText("")
                setloading(false);
                setdisabled(false);
                
                console.log(MYJSON)
                
            });
        }
  }


  function postWithAI(action)
  {

    if(text.length < 50)
    {
            handleShow();
            seterrorMessage("You must start typing a post to leverage AI tools. Min 50 chars.")
    }
    else
    {
        setloading(true);
        setdisabled(true);

            const jwtToken = myContext.jwtToken;
            fetch('https://postcraftai.com/wp-json/api/v1/postWithAI', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ textBoxContent:  text, action: action })
            }) .then(data => data.json()).then(MYJSON =>{
                
                if(action == "fix_grammar" || action == "add_emojis" || action == "add_hastags" || action == "create_cta" || action == "post_ideas" || action == "make_shorter")
                {
                    setText(MYJSON.answer)
                }
                else
                {
                    setText(text + "\n" + MYJSON.answer)
                }
                
                setloading(false);
                setdisabled(false);
                
                
            });

}

  }

  function showToolTip()
  {
    return (<>
    {showModal && (
        <div className="tooltip-new" ref={tooltipRef}>
          <div className="tooltip-content">
            
            <div class="row">
                <div class="col-12 mb-3 small_txt"><FontAwesomeIcon icon={faLightbulb} className='coloredIcon2' /> Use these AI tools to improve your post</div>
            <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("finish_post") }}><FontAwesomeIcon icon={faFlagCheckered} className='coloredIcon' /> Finish Post</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("post_ideas") }}><FontAwesomeIcon icon={faRocket} className='coloredIcon' /> Post Ideas</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("create_cta") }}><FontAwesomeIcon icon={faStar} className='coloredIcon' /> Create CTA</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("make_shorter") }}><FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='coloredIcon' /> Make Shorter</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("make_longer") }}><FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='coloredIcon' /> Make Longer</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("add_emojis") }}><FontAwesomeIcon icon={faFaceSmile} className='coloredIcon' /> Add Emojis</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("active_voice") }}><FontAwesomeIcon icon={faDumbbell} className='coloredIcon' /> Active Voice</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("fix_grammar") }}><FontAwesomeIcon icon={faCog} className='coloredIcon' /> Fix Grammar</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("make_formal") }}><FontAwesomeIcon icon={faUserTie} className='coloredIcon' /> More Formal</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("improve_post") }}><FontAwesomeIcon icon={faWandMagic} className='coloredIcon' /> Improve Post</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("add_hastags") }}><FontAwesomeIcon icon={faWandMagic} className='coloredIcon' /> Add Hastags</a></div>
                <div class="col-6 mb-2"><a className="btn-ai-idea" onClick={() =>{ postWithAI("add_listicle") }}><FontAwesomeIcon icon={faWandMagic} className='coloredIcon' /> Add Listicle</a></div>
            </div>
          </div>
        </div>
      )}
      </>)
  }


  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };



  function editPostAction(item)
  {
        const postID = item.id
        setText(item.content);

        setActiveTab('tab1');
        setactiveDraftId(postID);

  }

  //---------------------------------------------
  //
  //    function
  //
  //---------------------------------------------

  function editScheduledAction(item)
  {
        const postID = item.id
        setText(item.content);

        setActiveTab('tab1');
        setactiveScheduledId(postID);

  }


  //---------------------------------------------
  //
  //    function
  //
  //---------------------------------------------

  function deleteDraft(item, index)
  {
    setloading(true);

    const jwtToken = myContext.jwtToken;
    fetch('https://postcraftai.com/wp-json/api/v1/deleteDraft', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ pid:  item.pid })
    }) .then(data => data.json()).then(MYJSON =>{
        
      myContext.removeFromDrafts(index);

        
        setloading(false);
        
        
    });
  }

  //---------------------------------------------
  //
  //    return 
  //
  //---------------------------------------------


  function editDraftAction(item, index)
  {

    
    const postID = item.pid
    setText(item.content);

    setActiveTab('tab1');
    setactiveDraftId(postID);



  }

  return (
  
  
    <MainStructure activeItem={"write-post"}>

  


<Modal show={schedulePostModalShow} onHide={handleCloseScheduleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Your Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Select your date and time to publish your post on LinkedIn</p>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          showTimeSelect
          timeIntervals={10} // Set time intervals to 30 minutes
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseScheduleModal}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleConfirmSchedulePost(selectedDate)}>
          Schedule Post
        </Button>
      </Modal.Footer>
    </Modal>


<Modal show={showModal2} onHide={handleClose}>
        <Modal.Body style={{backgroundColor:"#F7918F", color: "black"}}>
         {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
 
        </Modal.Footer>
      </Modal>


      {
        infoLoaded == true ?
    

    <div className="container p-0">
    <div className='row'>

 

<div className='col-12 '>

    <Nav variant="tabs">
        <NavItem>
          <NavLink
            onClick={() => handleTabClick('tab1')}
            active={activeTab === 'tab1'}
          >
           Compose Message
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => handleTabClick('tab2')}
            active={activeTab === 'tab2'}
          >
           Drafts
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => handleTabClick('tab3')}
            active={activeTab === 'tab3'}
          >
            Scheduled
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            onClick={() => handleTabClick('tab4')}
            active={activeTab === 'tab4'}
          >
            Posted
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent>
        <TabPane eventKey="tab1" style={{paddingTop: 15}} active={activeTab === 'tab1'}>



    {writeTextBox()}
    {toolsBox()}

{ loading == true ?
<div className='mt-4'>
    <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner></div> : null }

<div className="mt-5">
{LinkedInConnectButton()}
</div>



        </TabPane>
        <TabPane eventKey="tab2" style={{paddingTop: 15}} active={activeTab === 'tab2'}>
          
        {
            myContext.drafts.length == 0 ?
            <div class="alert alert-warning">There are drafts yet</div> : 
            <>           

          {myContext.drafts.map((item, index) => {
            const showFull = showFullContent[index];
            

            return (
              <div className="card mb-2 p-3" key={index}>
                <div className='d-flex'>
                  <div className='mr-2'>
                    <img src="https://postcraftai.com/img/linkedin.png" style={{width: 20}} alt="Example Image" />
                  </div>
                  <div className="flex-grow-1" style={{fontWeight:"700"}}>
                    {item.content.substring(0, 60)}
                  </div>
                  <div className="">
                    {formatDateToUserTimezone(item.posted_date)}
                  </div>
                  <div className='ml-2'>

                  <a onClick={() => { editDraftAction(item, index) }} style={{marginRight: 5}}>
    <img src="https://postcraftai.com/img/edt.png" style={{width: 20}} alt="view" />
  </a>



  <a onClick={() => { deleteDraft(item, index) }} style={{marginRight: 5}}>
                      <img src="https://postcraftai.com/img/remove.png" style={{width: 20}} alt="view" />
                    </a>

  <a onClick={() => toggleShowFullContent(index)}>
                      <img src="https://postcraftai.com/img/eye.png" style={{width: 20}} alt="view" />
                    </a>
                  </div>
                </div>
                {/* Show full content on button click */}
                {showFull && (
                  <p className="pt-4">{formatContentWithBreaks(item.content)}</p>
                )}
              </div>
            );
          })}
        
            </> }


        </TabPane>
        <TabPane eventKey="tab3" style={{paddingTop: 15}} active={activeTab === 'tab3'}>
        {
            myContext.scheduled.length == 0 ?
            <div class="alert alert-warning">There are no schedule posts yet</div> : 
            <>
        
          {myContext.scheduled.map((item, index) => {
            const showFull = showFullContent2[index];

       

            return (
              <div className="card mb-2 p-3" key={index}>
                <div className='d-flex'>
                  <div className='mr-2'>
                    <img src="https://postcraftai.com/img/linkedin.png" style={{width: 20}} alt="Example Image" />
                  </div>
                  <div className="flex-grow-1" style={{fontWeight:"700"}}>
                    {item.content.substring(0, 60)}
                  </div>
                  <div className="">
                    {formatDateToUserTimezone(item.scheduled_date)}
                  </div>
                  <div className='ml-2'>
                  <a onClick={() => {

editScheduledAction(item)

                   }} style={{marginRight: 5}} alt="Edit post">
    <img src="https://postcraftai.com/img/edt.png" style={{width: 20}} alt="view" />
  </a>

  <a onClick={() => { }} style={{marginRight: 5}}>
                      <img src="https://postcraftai.com/img/remove.png" style={{width: 20}} alt="view" />
                    </a>

                    <a onClick={() => toggleShowFullContent2(index)}>
                      <img src="https://postcraftai.com/img/eye.png" style={{width: 20}} alt="view" />
                    </a>
                  </div>
                </div>
                {/* Show full content on button click */}
                {showFull && (
                  <p className="pt-4">{formatContentWithBreaks(item.content)}</p>
                )}
              </div>
            );
          })}
    
            </>
        }


        
        </TabPane>

        <TabPane eventKey="tab4" style={{paddingTop: 15}} active={activeTab === 'tab4'}>
        
        {
            myContext.published.length == 0 ?
            <div class="alert alert-warning">There are posts yet</div> : 
            <>
        

        

          {myContext.published.map((item, index) => {
            const showFull = showFullContent3[index];
            

            return (
              <div className="card mb-2 p-3" key={index}>
                <div className='d-flex'>
                  <div className='mr-2'>
                    <img src="https://postcraftai.com/img/linkedin.png" style={{width: 20}} alt="Example Image" />
                  </div>
                  <div className="flex-grow-1" style={{fontWeight:"700"}}>
                    {item.content.substring(0, 60)}
                  </div>
                  <div className="">
                    {formatDateToUserTimezone(item.posted_date)}
                  </div>
                  <div className='ml-2'>
                    
                    <a onClick={() => toggleShowFullContent3(index)}>
                      <img src="https://postcraftai.com/img/eye.png" style={{width: 20}} alt="view" />
                    </a>
                  </div>
                </div>
                {/* Show full content on button click */}
                {showFull && (
                  <p className="pt-4">{formatContentWithBreaks(item.content)}</p>
                )}
              </div>
            );
          })}
        
            </> }


        </TabPane>
      </TabContent>


      </div>
 


</div>

      
      

    </div> : <LoadingSpinner></LoadingSpinner> }

    </MainStructure>



  )
}

export default WritePost