import React from 'react'
import TopMenu from './TopMenu'
import SideMenu from '../sidebar/SideMenu'
import MyFooter from './MyFooter'

function MainStructure({children , activeItem}) {
  return (
    

    <div className="wrapper">

                    <SideMenu activeItem={activeItem}></SideMenu>

                    <div className="main">
                                    <TopMenu></TopMenu>
                                


                                    <main className="content">
                                        {children}
                                    </main>
                                    
                                    <MyFooter></MyFooter>

                    </div>
    </div> 


  )
}

export default MainStructure