import React, { createContext, useContext, useState, useRef, useEffect } from 'react';


const AuthContext = createContext();


export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

  const [infoLoaded, setInfoLoaded] = useState(false);
    const [linkedinconnected, setlinkedinconnected] = useState(false);
    const [jwtToken, setJwt] = useState(null);
    const [picture, setpicture] = useState("https://postcraftai.com/img/av.png");
    const [name, setname] = useState("User");

    const [scheduled, setscheduled] = useState([]);
    const [published, setpublished] = useState([]);
    const [drafts, setdrafts] = useState([]);


    const addScheduledItem = (newItem) => {
      setscheduled([...scheduled, newItem]);
    };

    const addDraftItem = (newItem) => {
      setdrafts([...drafts, newItem]);
    };
    


    const removeFromDrafts = (indexToRemove) => {
      setdrafts(prevData => {
        // Create a copy of the original array
        const newData = [...prevData];
        // Remove the item at the specified index
        newData.splice(indexToRemove, 1);
        // Set the state with the updated array
        return newData;
      });
    };


    const setJWT = async () => {
        // Wait for the localStorage.getItem() to resolve before proceeding
        const tokenFromLocalStorage = await new Promise((resolve) => {
          resolve(localStorage.getItem('jwtToken'));
        });
  
        setJwt(tokenFromLocalStorage)
  
  
        fetch('https://postcraftai.com/wp-json/api/v1/getAllInformation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenFromLocalStorage}`
          }
        }).then(data => data.json()).then(MYJSON =>{


          
  
          if(MYJSON.data.linkedin_connected == 1)
          {
            setlinkedinconnected(true)

          }

          if(MYJSON.data.picture.length > 2)
          {
                setpicture(MYJSON.data.picture);
          }

          if(MYJSON.scheduled.length > 0)
            {
              setscheduled(MYJSON.scheduled)
            }

            if(MYJSON.published.length > 0)
              {
                setpublished(MYJSON.published)
              }


              if(MYJSON.drafts.length > 0)
                {
                  setdrafts(MYJSON.drafts)
                }

                    if(MYJSON.data.name.length > 1)
                    setname(MYJSON.data.name) 

                    

                    setInfoLoaded(true);
      });
  

      }

    useEffect(() => {

        setJWT();


    }, []);


    return (
        <AuthContext.Provider value={{ removeFromDrafts, linkedinconnected, jwtToken, setlinkedinconnected, picture, setpicture,  infoLoaded,
          drafts, setdrafts, name,
          published, setpublished,
            scheduled, addScheduledItem,
            addDraftItem
         }}>
          {children}
        </AuthContext.Provider>
      );

}