import React from 'react' 
import * as Icon from 'react-feather';
import SidebarLink from './SidebarLink';
 
import { useContext } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';

function SideMenu({activeItem}) {


  var activeDashboard = "";
  var activeChats = "";
  var activeTraffic = "";
  var activeTickets = ""; 
  var activeBilling = ""; 
  var activeStatistics = ""; 
  var activeReports = ""; 
  var activeAgents = "";

  const myContext = useAuth();
  const navigate = useNavigate();
  

  if(activeItem == "dashboard") activeDashboard = "active";
  if(activeItem == "chats") activeChats = "active";
  if(activeItem == "traffic") activeTraffic = "active";
  if(activeItem == "tickets") activeTickets = "active";
  if(activeItem == "agents") activeAgents = "active";

 
 

  return (

<nav id="sidebar" className="sidebar js-sidebar">
			<div className="sidebar-content js-simplebar" data-simplebar="init">
        <div className="simplebar-wrapper" style={{margin: "0px"}}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          
          <div className="simplebar-mask"><div className="simplebar-offset" style={{right: "0",  bottom: "0"}}>
            <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ height: "100%", overflow: "visible" }}>
              <div className="simplebar-content" style={{padding: "0px"}}>
				
            <a className="sidebar-brand" onClick={() =>{ 
              navigate("/")
            }}><img src={"https://postcraftai.com/img/logo2.png"} style={{maxWidth: "150px"}} /></a>

				<ul className="sidebar-nav">
					<li className="sidebar-header">
						Dashboard
					</li>


          <SidebarLink linkTitle={"Write Post"} link={"/write-post"} active={activeItem} id={"write-post"}>
              <Icon.PenTool className='align-middle' />
          </SidebarLink>

          <SidebarLink linkTitle={"Dashboard"} link={"/"} active={activeItem} id={"dashboard"}>
              <Icon.Sliders className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Hook Generator"} link={"/hook-generator"} active={activeItem} id={"hook-generator"}>
              <Icon.Aperture className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Post Generator"} link={"/post-generator"} active={activeItem} id={"post-generator"}>
              <Icon.Activity className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Carousel Generator"} link={"/carousel-generator"} active={activeItem} id={"carousel-generator"}>
              <Icon.FileText className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Search Posts"} link={"/search-posts"} active={activeItem} id={"search-posts"}>
              <Icon.Search className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Repurpose Content"} link={"/repurpose-content"} active={activeItem} id={"repurpose-content"}>
              <Icon.Speaker className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Editor's Choice"} link={"/editor-choice"} active={activeItem} id={"editor-choice"}>
              <Icon.User className='align-middle' />
          </SidebarLink>



          <li className="sidebar-header">
						CRM
					</li>

       
          <SidebarLink linkTitle={"CRM"} link={"/crm"} active={activeItem} id={"crm"}>
              <Icon.User className='align-middle' />
          </SidebarLink>

          <li className="sidebar-header">
						Account
					</li>




          <SidebarLink linkTitle={"Billing"} link={"/billing"} active={activeItem} id={"billing"}>
              <Icon.CreditCard className='align-middle' />
          </SidebarLink>


          <SidebarLink linkTitle={"Settings"} link={"/settings"} active={activeItem} id={"settings"}>
              <Icon.Settings className='align-middle' />
          </SidebarLink>



          <SidebarLink linkTitle={"Help"} link={"/help"} active={activeBilling}>
              <Icon.HelpCircle className='align-middle' />
          </SidebarLink>


        

 


          <li className={"sidebar-item " }>
						<a className="sidebar-link" href="/support">
              <Icon.Cpu className='align-middle' /> <span className="align-middle">Support</span>
            </a>
          </li>



          <li className={"sidebar-item " }>
						<a className="sidebar-link" onClick={() =>{

              localStorage.removeItem('myLoggedInUser2');
              window.location.href="/";

            }}>
              <Icon.LogOut className='align-middle' /> <span className="align-middle">Logout</span>
            </a>
          </li>

  
				</ul>

        
        <div className="sub-menu-position">

            

        <ul>
          <li>

 

            
 
            
            </li>
        </ul></div>

		 
        </div>
        </div></div></div></div></div>
		</nav>



    
 


        

 


  )
}

export default SideMenu