import React from 'react'
import MainStructure from '../../components/general/MainStructure';
import { useNavigate } from 'react-router-dom'

import { LinkedInConnectButton } from '../../components/general/LinkedInAuth';

function Settings() 
{

  const navigate =useNavigate();

  return (
  
  
    <MainStructure activeItem={"chats"}>

    <div className="container p-0">
      
      <h2>Settings</h2>

        {LinkedInConnectButton()}

    </div>

    </MainStructure>



  )
}

export default Settings