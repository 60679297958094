import React from 'react'
import { Link } from 'react-router-dom';

function SidebarLink({children, linkTitle, link, active, id}) {

  var activeWord = "";
  if(active == id) activeWord = "active";

 

  return (
    <li className={"sidebar-item " + activeWord}>
						<Link className="sidebar-link" to={link}>
              {children} <span className="align-middle">{linkTitle}</span>
            </Link>
          </li>
  )
}

export default SidebarLink