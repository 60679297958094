 
import '../App.css';
import { useNavigate } from 'react-router-dom';
import React from 'react'
import 'bootstrap/dist/js/bootstrap.bundle'
import MainStructure from '../components/general/MainStructure';
import { Button } from 'bootstrap/dist/js/bootstrap.bundle';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { useState, useEffect } from 'react';
 import { useContext } from 'react'; 

 import { useAuth } from '../AuthContext';

 import { LinkedInConnectButton } from '../components/general/LinkedInAuth';

function Home() {

    const [homeInformationLoaded, setHomeInformationLoaded] = useState(false)

    const navigate = useNavigate();

    const myContext = useAuth()
    const infoLoaded = myContext.infoLoaded


    const [showFullContent, setShowFullContent] = useState({});
    const [showFullContent2, setShowFullContent2] = useState({});

    const toggleShowFullContent = (index) => {
        setShowFullContent(prevState => ({
          ...prevState,
          [index]: !prevState[index]
        }));
      };


      const toggleShowFullContent2 = (index) => {
        setShowFullContent2(prevState => ({
          ...prevState,
          [index]: !prevState[index]
        }));
      };

      const formatContentWithBreaks = (content) => {
        return content.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < content.length - 1 && <br />} {/* Add <br/> except for the last line */}
          </React.Fragment>
        ));
      };
    

    useEffect(() =>{

 

    }, [])

    function formatDateToUserTimezone(gmtDate) {
        // Parse the GMT date string into a Date object
        const dateParts = gmtDate.split(/[- :]/);
        const date = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]));
      
        // Format the date according to the user's locale and timezone
        const formattedDate = date.toLocaleString(undefined, {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          hour12: true, // Use 12-hour format
          hour: 'numeric', // Display hours
          minute: 'numeric', // Display minutes
          month: 'long', // Display month
          day: 'numeric', // Display day
          year: 'numeric' // Display year
        });
      
        return formattedDate;
      }
    
 

  return (
    <MainStructure activeItem={"dashboard"}>

        {
            infoLoaded == true ?
         

    <div className="container p-0">

        <div className="d-flex">
            <div className='flex-grow-1'>

                        <div className='d-flex mb-5'>
                        <div className='pe-3'>
                                <img src={myContext.picture} className='avatar img-fluid rounded me-1' />
                        </div>

                        <div className=''>
                                <h1 className="h3 mb-0" style={{fontWeight: 800}}>Hello {myContext.name}</h1>
                                <p>Check out your stats and suggestions for using PostCraft AI</p>
                        </div>
                        </div>
            </div>


            <div className='pt-3 '>
                    <a onClick={()=>{
                        navigate("/settings")
                    }} className='btn btn-outline-primary'>Edit Profile Settings</a>
            </div>

        </div>
        




    {/*------------------------------------------------------------*/}


{LinkedInConnectButton()}


    <h4 className="subtitle-1">Your Scheduled Posts</h4>


    {
            myContext.scheduled.length == 0 ?
            <p className='mb-5'>There are no scheduled posts at this time. <a style={{color:"blue"}} onClick={() =>{
                navigate("/write-post")
            }}>Write something.</a></p>
             : 
            <>
        

        <div className="mb-5">

          {myContext.scheduled.slice(0,5).map((item, index) => {
            const showFull = showFullContent[index];
            

            return (
              <div className="card mb-2 p-3" key={index}>
                <div className='d-flex'>
                  <div className='mr-2'>
                    <img src="https://postcraftai.com/img/linkedin.png" style={{width: 20}} alt="Example Image" />
                  </div>
                  <div className="flex-grow-1" style={{fontWeight:"500", color:"#6e6e6e"}}>
                    {item.content.substring(0, 60)}
                  </div>
                  <div className="">
                    {formatDateToUserTimezone(item.scheduled_date)}
                  </div>
                  <div className='ml-2'>
                    <a onClick={() => toggleShowFullContent(index)}>
                      <img src="https://postcraftai.com/img/eye.png" style={{width: 20}} alt="view" />
                    </a>
                  </div>
                </div>
                {/* Show full content on button click */}
                {showFull && (
                  <p className="pt-4">{formatContentWithBreaks(item.content)}</p>
                )}
              </div>
            );
          })} </div>
        
            </> }

    



    <h4 className="subtitle-1">Your Latest Posts</h4>

    {
            myContext.published.length == 0 ?
            <div class="alert alert-warning">There are posts yet</div> : 
            <>
        

        <div className="mb-5">

          {myContext.published.slice(0,5).map((item, index) => {
            const showFull = showFullContent2[index];
            

            return (
              <div className="card mb-2 p-3" key={index}>
                <div className='d-flex'>
                  <div className='mr-2'>
                    <img src="https://postcraftai.com/img/linkedin.png" style={{width: 20}} alt="Example Image" />
                  </div>
                  <div className="flex-grow-1" style={{fontWeight:"700"}}>
                    {item.content.substring(0, 60)}
                  </div>
                  <div className="">
                    {formatDateToUserTimezone(item.posted_date)}
                  </div>
                  <div className='ml-2'>
                    <a onClick={() => toggleShowFullContent2(index)}>
                      <img src="https://postcraftai.com/img/eye.png" style={{width: 20}} alt="view" />
                    </a>
                  </div>
                </div>
                {/* Show full content on button click */}
                {showFull && (
                  <p className="pt-4">{formatContentWithBreaks(item.content)}</p>
                )}
              </div>
            );
          })} </div>
        
            </> }

    <h4 className="subtitle-1">Some shortcuts to help you</h4>

    <div className="row">
    <div className='col col-md-4'>
        <div className="card p-3">
            <div className="d-flex">
                <div className='pe-3'><img src="https://cdn.easychatdesk.com/images/life-preserver.png" width="42" /></div>
                <div className=''><p className='fs-5'>Search through our help center to help you understand how our systems work.</p>
                  <a className='btn btn-outline-primary'>Go to help center</a>
                </div>
            </div>
            
            
        </div>
    </div>

    <div className='col col-md-4'>
        <div className="card p-3">

        

        <div className="d-flex">
                <div className='pe-3'><img src="https://cdn.easychatdesk.com/images/light-bulb.png" width="42" /></div>
                <div className=''><p className='fs-6'>Check our our FAQ section for some quick questions with some quick answers.</p>
                <a className='btn btn-outline-primary'>Go to FAQ Section</a>
                </div>
            </div>
            

         
        </div>
    </div>

    <div className='col col-md-4'>
    <div className="card p-3">

    <div className="d-flex">
                <div className='pe-3'><img src="https://cdn.easychatdesk.com/images/community.png" width="42" /></div>
                <div className=''><p className='fs-6'>Visit our community to find out what are people discussing.</p>
                <a className='btn btn-outline-primary'>Go to Community</a>
                </div>
            </div>
            


    
    </div>
    </div>

    </div>
   

    </div> : <LoadingSpinner></LoadingSpinner> }

 </MainStructure>

  )
}

export default Home