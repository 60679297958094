import React from 'react'
import { useState, useEffect } from 'react'
import { useAuth } from '../../AuthContext'

function LinkedInConnectButton() {


    const myContext = useAuth()

    const handleConnectLinkedin = () => {
        const clientId = "776m1p4wxfxf62";
        const redirectUri = encodeURIComponent("https://postcraftai.com/wp-json/api/v1/callback");
        const scope = encodeURIComponent("openid profile email w_member_social");
        const state = "some_data_here";  // Should be generated per request
        const jwt_token = "123" ;
    
     
        const linkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&jwt_token=${jwt_token}&state=${state}`;
    
        // Open a new window
        const width = 600, height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
    
        const authWindow = window.open(
            linkedInUrl,
            'LinkedInAuth',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
        );
    
    
    
        window.addEventListener("message", event => {
            if (event.data.type === "linkedin-authenticated") {
                // Close the popup window or perform any other actions
                myContext.setlinkedinconnected(true);
                const linkedInAccessToken = event.data.token;
    
     
                const jwtToken = myContext.jwtToken;
    
    
     fetch('https://postcraftai.com/wp-json/api/v1/registerAccessToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ linkedInAccessToken })
            }) .then(data => data.json()).then(MYJSON =>{
                
                myContext.setpicture(MYJSON.picture)
                myContext.setname(MYJSON.name)
                
            });
    
    
                
                authWindow.close();
                // Optionally, you can update the UI to reflect the user's authenticated status
            }
        });
    
    
        // Optional: Monitor the popup window
        const interval = setInterval(() => {
            if (authWindow.closed) {
                clearInterval(interval);
                // Handle the case where the user closes the popup without completing the flow
                console.log("User closed the window");
            }
        }, 1000);
    };



    const LinkedInConnectButtonX = () =>
        {
       
      
          if(myContext.linkedinconnected == true)
          {
              return(
                  <>
                  <div className='alert alert-secondary'>Your LinkedIn account is connected</div>
                  </>
              )
          }
          else
          return(
              <>
              <button className='btn btn-primary mb-4' onClick={handleConnectLinkedin}>Connect your LinkedIn Account</button>
              </>
          )
        }

        

        return <LinkedInConnectButtonX />;  

}

export {  LinkedInConnectButton };