 
import './App.css';
 
import './styles/style.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import { Link } from "react-router-dom";
 
import { useContext, createContext, useState, useEffect } from 'react'

 
 
import LoginScreen from './screens/LoginScreen';
import Home from './routes/Home';

import CarouselGenerator from './routes/carouselGenerator/CarouselGenerator';
 

import {
  BrowserRouter,
  Routes, Navigate,
  Route
} from "react-router-dom";



 
 

import WritePost from './routes/WritePost';
import HookGenerator from './routes/hookGenerator/HookGenerator';


import { AuthProvider } from "./AuthContext";
import { useAuth } from "./AuthContext";
import PostGenerator from './routes/postGenerator/PostGenerator';
import RepurposeContent from './routes/repurposeContent/RepurposeContent';
import SearchPosts from './routes/searchPosts/SearchPosts';
import Billing from './routes/billing/Billing';
import Settings from './routes/settings/Settings';
import CrmPage from './routes/crm/CrmPage';

function App() { 

  function isUserLoggedInTrue()
  {
    const user = localStorage.getItem('myLoggedInUser2');
    if (user === undefined || user == null) {
      return false;
     }
     else
    return true;
  }


  const ProtectedRoute = ({ children }) => {
    const user  = isUserLoggedInTrue();
    if (user == false) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
    return children;
  };
 
  return (   
 

    <AuthProvider>    
          <BrowserRouter>

    <Routes> 

              <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
 
              
              <Route path="hook-generator" element={<ProtectedRoute><HookGenerator /></ProtectedRoute>} />

              <Route path="hook-generator" element={<ProtectedRoute><HookGenerator /></ProtectedRoute>} />
              <Route path="post-generator" element={<ProtectedRoute><PostGenerator /></ProtectedRoute>} />
              <Route path="carousel-generator" element={<ProtectedRoute><CarouselGenerator /></ProtectedRoute>} />
              <Route path="search-posts" element={<ProtectedRoute><SearchPosts /></ProtectedRoute>} />
              <Route path="repurpose-content" element={<ProtectedRoute><RepurposeContent /></ProtectedRoute>} />
              <Route path="editor-choice" element={<ProtectedRoute><HookGenerator /></ProtectedRoute>} />

              <Route path="crm" element={<ProtectedRoute><WritePost /></ProtectedRoute>} />
              <Route path="billing" element={<ProtectedRoute><Billing /></ProtectedRoute>} />
              <Route path="settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path="crm" element={<ProtectedRoute><CrmPage /></ProtectedRoute>} />
 
              <Route path="write-post" element={<ProtectedRoute><WritePost /></ProtectedRoute>} />
            

 


              <Route path="/login" element={<LoginScreen />} />

   </Routes>  

   </BrowserRouter> 
   </AuthProvider>


    
  );
}

export default App;
