import React from 'react'
import MainStructure from '../../components/general/MainStructure';
import { useNavigate } from 'react-router-dom'

function CrmPage() 
{

  const navigate =useNavigate();

  return (
  
  
    <MainStructure activeItem={"chats"}>

    <div className="container-fluid p-0">
      
      <div className='w-100 text-center'>

        CRM - coming soon

      </div>

    </div>

    </MainStructure>



  )
}

export default CrmPage