import React from 'react'
import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom';

import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';


function useTitle(title) {
    useEffect(() => {
      const prevTitle = document.title
      document.title = title
      return () => {
        document.title = prevTitle
      }
    })
  }
  


function LoginScreen() {


const [username, setUserName] = useState();
const [password, setPassword] = useState();
const [processing, setProcessing] = useState(false);
const [showError, setShowError] = useState(false);
const [errorMessage, setErrorMessage] = useState("");

 

const navigate = useNavigate();


function loginUser(credentials) {

    setProcessing(true);
    setShowError (false)

    fetch('https://postcraftai.com/wp-json/api/v1/executeLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
        .then(data => data.json()).then(MYJSON =>{
            

            setProcessing(false);


            if(MYJSON.response == 200)
            {
             
                const jwtToken = MYJSON.jwtToken; 

                localStorage.setItem('myLoggedInUser2', JSON.stringify(MYJSON));
                localStorage.setItem('jwtToken', jwtToken);
 

                navigate("/")
                window.location.reload(true);
               

            }
            else
            {
                setErrorMessage(MYJSON.reason)
                setShowError (true)

 
            }

            

        })


}


useTitle("Login to PostCraftAI")


const handleSubmit = async e => {
    e.preventDefault();
    loginUser({ email: username, password: password });
    //setToken(token);
  }


  return (
    <div className="container">
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h2 className="text-center heading-ttl text-dark mt-5">PostCraft AI Dashboard Login</h2>
        <div className="text-center mb-5 text-dark">Login to your account to start growing your LinkedIn</div>
        <div className="card my-5" id="login-form1">

          <form onSubmit={handleSubmit} className="card-body cardbody-color p-lg-5">

            <div className="text-center">
              <img src="https://postcraftai.com/img/logo.png" 
                width="200px" alt="profile" className='mb-5' />
            </div>

    {
        showError == true ? 
        <div className="alert alert-danger">{errorMessage}</div> : <></> 
    }



            {
                        processing == true ? 
       
                        <LoadingSpinner></LoadingSpinner>: <></>


            }

         

            <div className="mb-3">
              <input type="text" className="form-control" id="Username" onChange={e => { setUserName(e.target.value); }} aria-describedby="emailHelp"
                placeholder="Email Address..." />
            </div>
            <div className="mb-3">
              <input type="password" className="form-control" id="password" onChange={e => setPassword(e.target.value)} placeholder="Password..." />
            </div>
            <div className="text-center"><button type="submit"  className="btn btn-color border-radius px-5 mb-5 w-100">Login</button></div>
            <div id="emailHelp" className="form-text text-center  text-dark">Not registered? <a href="https://postcraftai.com/register-for-free/" className="text-dark fw-bold"> Create an Account</a> </div>
            <div id="emailHelp" className="form-text text-center mb-5 text-dark"><a href="https://postcraftai.com/lost-password/" className="text-dark fw-bold">Lost your password ?</a>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  )
}

export default LoginScreen